import React, { useEffect, useState } from "react";
import { IQuestionSubmitValues } from "@adm-media/adam-client";
import { Channel } from "pusher-js";
import { t } from "i18next";
import BasicButton from "../../buttons/BasicButton/BasicButton";
import { selectAppId } from "../../../store/reducers/appReducer";
import { selectPickedEpisodes } from "../../../store/reducers/episodesReducer";
import { selectUser } from "../../../store/reducers/userReducer";
import {
    addQuestion,
    fetchQuestions,
    selectAllQuestions,
    selectIsLoadingQuestions,
    selectIsMakingAsReadQuestion,
    selectIsUpdatingQuestion,
} from "../../../store/reducers/qaReducer";
import Col from "../Col";
import Row from "../Row";
import Spinner from "../../Spinner/Spinner";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { subscribeToQAQuestionChannel } from "../../../pusher";
import { showModal } from "../../../store/reducers/modalsReducer";
import { QA_QUESTION_MODAL } from "../../modals";
import QuestionCard from "../questionCard/QuestionCard";

interface ColProps {
    title: string;
}

export interface QuestionsBoardProps {
    withAddNew?: boolean;
    cols: ColProps[];
    onSelect: (id: any) => void;
    onCancelSelection: (id: any) => void;
    onSubmit: (payload: IQuestionSubmitValues) => void;
}

const QuestionsBoard: React.FC<QuestionsBoardProps> = ({
    cols,
    onSelect,
    onCancelSelection,
    onSubmit,
    withAddNew,
}) => {
    const { dispatch, useSelector } = useAppDispatch();
    const appId = useSelector(selectAppId);
    const loading = useSelector(selectIsLoadingQuestions);
    const { id: episodeId } = useSelector(selectPickedEpisodes);
    const updatingQuestion = useSelector(selectIsUpdatingQuestion);
    const isMakingAsRead = useSelector(selectIsMakingAsReadQuestion);
    const { token, isModerator, isChairman } = useSelector(selectUser);
    const { items, selected, submitted } = useSelector(selectAllQuestions);

    const [qaQuestionChannel, setQaQuestionChannel] = useState<
        Channel | undefined
    >(undefined);

    useEffect(() => {
        if (episodeId) {
            dispatch(fetchQuestions(episodeId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [episodeId]);

    useEffect(() => {
        if (appId && token) {
            let episodeID = String(episodeId);
            setQaQuestionChannel(
                subscribeToQAQuestionChannel(
                    {
                        token,
                        appId,
                        episodeId: episodeID,
                        isModerator,
                        isChairman,
                    },
                    (question) => dispatch(addQuestion(question))
                )
            );
        }

        return () => {
            if (qaQuestionChannel) {
                qaQuestionChannel.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appId, token]);

    const handleToggleShowForm = () =>
        dispatch(
            showModal({
                modal: QA_QUESTION_MODAL,
            })
        );

    const cancelButtonText = t("cancel");
    const submitButtonText = isModerator
        ? t("sendQuestionToTheChairman")
        : isChairman
        ? t("markAsRead")
        : undefined;

    return (
        <div className="questions-board" data-testid="questions-board">
            <div className="questions-board__scroll">
                {loading ? (
                    <Spinner isFullPage={true} spinning={loading}>
                        <div />
                    </Spinner>
                ) : (
                    <Row className="questions-board__body">
                        <Col span={24}>
                            <Row gutter={32}>
                                <Col span={7}>
                                    <h1 className="questions-board__title">
                                        {cols[0].title}
                                    </h1>
                                </Col>
                                <Col span={10}>
                                    <Row>
                                        <Col span={12}>
                                            <h1 className="questions-board__title">
                                                {cols[1].title}
                                            </h1>
                                        </Col>
                                        {withAddNew && (
                                            <Col span={12}>
                                                <div className="questions-board__add-new">
                                                    <BasicButton
                                                        className="button"
                                                        onClick={
                                                            handleToggleShowForm
                                                        }
                                                        data-testid="ask-new-question-btn"
                                                    >
                                                        + {t("addNewQuestion")}
                                                    </BasicButton>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                                <Col span={7}>
                                    <h1 className="questions-board__title">
                                        {cols[2].title}
                                    </h1>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row gutter={32}>
                                <Col span={7}>
                                    <div className="questions-board__scrollable-box">
                                        <div
                                            className="questions-board__scrollable-column"
                                            data-testid="qa-to-process-column"
                                        >
                                            {items.map(
                                                ({
                                                    created_at: createdAt,
                                                    id,
                                                    original_content:
                                                        originalContent,
                                                    edited_content:
                                                        editedContent,
                                                    subscriber,
                                                }: any) => (
                                                    <QuestionCard
                                                        hoverable
                                                        onClick={() =>
                                                            onSelect(id)
                                                        }
                                                        date={createdAt}
                                                        key={id}
                                                        label={
                                                            subscriber?.email
                                                        }
                                                        question={
                                                            isModerator
                                                                ? originalContent
                                                                : editedContent
                                                        }
                                                        user={`${subscriber?.firstname} ${subscriber?.lastname}`}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                {/* Central col where moderator can see read questions */}
                                <Col span={10}>
                                    <div className="questions-board__scrollable-box">
                                        <div className="questions-board__scrollable-column">
                                            <div
                                                className="questions-board__selected-questions"
                                                data-testid="qa-selected-column"
                                            >
                                                {!selected.length && (
                                                    <span className="empty">
                                                        {t(
                                                            "selectAQuestionFromLeftSide"
                                                        )}
                                                    </span>
                                                )}
                                                {selected.map(
                                                    ({
                                                        created_at: createdAt,
                                                        id,
                                                        original_content:
                                                            originalContent,
                                                        edited_content:
                                                            editedContent,
                                                        subscriber,
                                                    }) => (
                                                        // This is the single card moderator will approve to chairman
                                                        <QuestionCard
                                                            date={createdAt}
                                                            editable={
                                                                isModerator
                                                            }
                                                            withActions={
                                                                isModerator ||
                                                                isChairman
                                                            }
                                                            key={id}
                                                            onDismiss={() =>
                                                                onCancelSelection(
                                                                    id
                                                                )
                                                            }
                                                            onSubmit={(
                                                                question
                                                            ) => {
                                                                onSubmit({
                                                                    id,
                                                                    episode_id:
                                                                        episodeId,
                                                                    edited_content:
                                                                        question,
                                                                    original_content:
                                                                        originalContent,
                                                                    confirmed:
                                                                        true,
                                                                });
                                                            }}
                                                            submitButtonText={
                                                                submitButtonText
                                                            }
                                                            cancelButtonText={
                                                                cancelButtonText
                                                            }
                                                            loading={
                                                                updatingQuestion ===
                                                                    id ||
                                                                isMakingAsRead ===
                                                                    id
                                                            }
                                                            label={
                                                                subscriber?.email
                                                            }
                                                            question={
                                                                isModerator
                                                                    ? originalContent
                                                                    : editedContent
                                                            }
                                                            user={`${subscriber?.firstname} ${subscriber?.lastname}`}
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={7}>
                                    <div className="questions-board__scrollable-box">
                                        <div
                                            className="questions-board__scrollable-column"
                                            data-testid="qa-submitted-column"
                                        >
                                            {/* List of submitte questions to chairman */}
                                            {submitted.map(
                                                ({
                                                    updated_at: updatedAt,
                                                    id,
                                                    edited_content:
                                                        editedContent,
                                                    subscriber,
                                                    original_content:
                                                        originalContent,
                                                }) => (
                                                    <QuestionCard
                                                        date={updatedAt}
                                                        key={id}
                                                        label={
                                                            subscriber?.email
                                                        }
                                                        question={
                                                            editedContent
                                                                ? editedContent
                                                                : originalContent
                                                        }
                                                        submitted
                                                        user={`${subscriber?.firstname} ${subscriber?.lastname}`}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
};

export default QuestionsBoard;
