import React, { Suspense, useEffect, useState } from "react";
import { subscribeToQAChannel } from "../../../pusher";
import { changeCurrentEpisodeQAEnableStatus } from "../../../store/reducers/episodesReducer";
import { Channel } from "pusher-js";
import { selectAppId } from "../../../store/reducers/appReducer";
import Spinner from "../../Spinner/Spinner";
import Layout from "../../../config/Layout";
import { theme1 } from "../../../test/__mocks__/theme1";
import useAppDispatch from "../../../hooks/useAppDispatch";

/**
 * Wrapper that creates a bridge between modal and quiz form
 * @returns
 */
const QAFormWrapper: React.FC = () => {
    const { dispatch, useSelector } = useAppDispatch();
    const appId = useSelector(selectAppId);

    const [qaChannel, setQaChannel] = useState<Channel | undefined>(undefined);

    const subscribeQA = async () => {
        const channelQA: any = await subscribeToQAChannel((payload) => {
            dispatch(changeCurrentEpisodeQAEnableStatus(payload));
        });

        setQaChannel(channelQA);
    };

    const spinner = <Spinner isFullPage={true} />;

    useEffect(() => {
        if (appId) {
            subscribeQA();
        }

        return () => {
            if (qaChannel) {
                qaChannel.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appId, qaChannel]);

    return (
        <Suspense fallback={spinner}>
            <div data-testid="qa-form-wrapper">
                <Layout nodes={theme1.qa.config || []} />
            </div>
        </Suspense>
    );
};

export default QAFormWrapper;
