import { Spin, SpinProps } from "antd";
import classNames from "classnames";
import { LoadingOutlined } from "@ant-design/icons";
import "./Spinner.scss";

export interface ISpinner extends SpinProps {
    isFullPage?: boolean;
    isLoading?: boolean;
    withWrapper?: boolean;
    isAppLoader?: boolean;
}

const Spinner: React.FC<ISpinner> = ({
    isLoading = true,
    withWrapper = false,
    isFullPage = false,
    isAppLoader = false,
}) => {
    const antIcon = <LoadingOutlined spin />;

    const spinner = (
        <Spin
            data-testid="spinner"
            indicator={antIcon}
            spinning={isLoading}
            className={classNames("spinner")}
        />
    );

    if (withWrapper || isFullPage)
        return (
            <div
                className={classNames(
                    "spinner-wrapper",
                    {
                        isFullPage,
                    },
                    { isAppLoader }
                )}
            >
                {spinner}
            </div>
        );
    else return spinner;
};

export default Spinner;
