import parse, { HTMLReactParserOptions, domToReact } from "html-react-parser";

/**
 * Used to create HTML instead of applying dangerouslySetInnerHTML only
 * @param params
 * @param options
 * @returns
 */
function parseHtml(
    params: string,
    options?: HTMLReactParserOptions
): ReturnType<typeof domToReact> {
    return parse(params, options);
}

export { parseHtml };
