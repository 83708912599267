const getToken = (): string | undefined => {
    const token = localStorage.getItem("token");

    return token || undefined;
};

const setToken = (token: string): void => {
    localStorage.setItem("token", token);
};

const clearToken = (): void => {
    localStorage.removeItem("token");
};

const isUserLoggedIn = (): boolean => {
    if (getToken()) return true;
    return false;
};

export { getToken, setToken, clearToken, isUserLoggedIn };
