import React from "react";
import Modal from "../Modal";
import classNames from "classnames";

interface QAManageQuestionProps {}

const QAManageQuestion: React.FC<QAManageQuestionProps> = () => {
    const IsLoading = false;
    const body = <>body</>;

    return (
        <Modal
            data-testid="qa-modal-manage"
            open
            centered
            className={classNames("qa-modal-manage")}
            body={body}
            isLoading={IsLoading}
        />
    );
};

export default QAManageQuestion;
