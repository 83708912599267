import { useLocation, useNavigate } from "react-router-dom";
import { HOME } from "../routes/routes";

/**
 * Hook that handle navigation that centralize the navigation methods
 * @returns
 */
export const useAppNavigation = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const redirectToHome = (condition: boolean, route?: string) => {
        if (condition) navigate(HOME || route);
    };
    return { redirectToHome, location, navigate };
};
