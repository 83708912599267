import {
    createAsyncThunk,
    createDraftSafeSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { buildQuery, serviceApi } from "../../utils/serviceApi";

import { QA } from "@adm-media/adam-client";

const QAClient = new QA({
    baseUrl: buildQuery(),
});

const fetchEvaluationQuestions = createAsyncThunk<any, any>(
    `qa/fetchEvaluationQuestions`,
    async (episodeId, thunkApi) => {
        const {
            app: { id },
        } = thunkApi.getState() as RootState;
        if (!id) throw Error("id does not exist");

        const api = await QAClient.fetchEvaluationQuestions(id, episodeId);
        const res = await serviceApi<typeof api>(api);
        return res.data.data;
    }
);

interface IEvaluationQuestion {
    id: number;
    episode_id: number;
    content: string;
    legend: string;
    points: number;
    question_type: number;
    is_radio: number;
    max_allowed_choices: number;
    is_required: number;
    options: any[];
    type_id: number;
    lang_id: string;
}

interface IEvaluationReducer {
    evaluationQuestions: {
        loading: boolean;
        items: IEvaluationQuestion[];
        error: any;
    };
}

const initialState: IEvaluationReducer = {
    evaluationQuestions: {
        loading: false,
        items: [],
        error: {} as any,
    },
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchEvaluationQuestions.pending, (state) => {
            state.evaluationQuestions.loading = true;
        });

        builder.addCase(
            fetchEvaluationQuestions.fulfilled,
            (state, { payload }) => {
                state.evaluationQuestions.loading = false;
                state.evaluationQuestions.items = payload;
            }
        );

        builder.addCase(
            fetchEvaluationQuestions.rejected,
            (state, { payload }) => {
                state.evaluationQuestions.loading = false;
                state.evaluationQuestions.error = payload;
            }
        );
    },
});

const selectState = (state: RootState) => state;

const selectSelfQa = createDraftSafeSelector(
    selectState,
    ({ evaluationForm }) => evaluationForm
);

const selectAllEvaluationQuestions = createDraftSafeSelector(
    selectSelfQa,
    ({ evaluationQuestions }) => evaluationQuestions
);

// eslint-disable-next-line no-empty-pattern
export const {} = appSlice.actions;

export { selectAllEvaluationQuestions, fetchEvaluationQuestions };

export default appSlice.reducer;
