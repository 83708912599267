import { Button, ButtonProps } from "antd";
import React from "react";

interface IBasicButton extends ButtonProps {}

const BasicButton: React.FC<IBasicButton> = ({ ...props }) => {
    return <Button data-testid="button" {...props} />;
};

export default BasicButton;
