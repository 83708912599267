import { useEffect, useMemo } from "react";
import {
    fetchEvaluationQuestions,
    selectAllEvaluationQuestions,
} from "../../store/reducers/evaluationReducer";
import { normalizeEvaluationQuestions } from "../../utils/normalize-evaluation-question";
import { selectPickedEpisodes } from "../../store/reducers/episodesReducer";
import useAppDispatch from "../../hooks/useAppDispatch";

const EnrichEvaluationForm = ({ ...props }) => {
    const { dispatch, useSelector } = useAppDispatch();
    const episode = useSelector(selectPickedEpisodes);

    const { items, loading: loadingQuestions } = useSelector(
        selectAllEvaluationQuestions
    );
    const normalizedQuestions = useMemo(
        () => normalizeEvaluationQuestions(items),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadingQuestions, items]
    );

    // Fetching evaluation question taking in argument only one episode ()
    useEffect(() => {
        if (!episode?.id) return;

        dispatch(fetchEvaluationQuestions(episode.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [episode]);

    return {
        evaluateQuestions: normalizedQuestions,
        loading: loadingQuestions,
        ...props,
    };
};

export default EnrichEvaluationForm;
