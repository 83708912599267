import { Suspense } from "react";
import Layout from "../../config/Layout";
import { theme1 } from "../../test/__mocks__/theme1";
import Spinner from "../../components/Spinner/Spinner";

const EvaluationPage: React.FC = () => {
    const spinner = <Spinner isFullPage={true} />;

    return (
        <Suspense fallback={spinner}>
            <div data-testid="evaluation-page">
                <Layout nodes={theme1.evaluationForm.config || []} />
            </div>
        </Suspense>
    );
};

export default EvaluationPage;
