import React from "react";
import Row from "../../components/ui/Row";
import { selectContent } from "../../store/reducers/appReducer";
import Spinner from "../../components/Spinner/Spinner";
import { parseHtml } from "../../utils/parseHtml";
import useAppDispatch from "../../hooks/useAppDispatch";
import "./Maintenance.scss";

const Maintenance: React.FC = () => {
    const { useSelector } = useAppDispatch();
    const { maintenance_message } = useSelector(selectContent);

    return (
        <Row
            className="maintenance-page"
            justify={"center"}
            align="middle"
            data-testid="maintenance"
        >
            {maintenance_message ? parseHtml(maintenance_message) : <Spinner />}
        </Row>
    );
};

export default Maintenance;
