import pusher from "./base";

interface IConfig {
    appId: string;
    episodeId: string;
    token: string;
    isModerator?: boolean;
    isChairman?: boolean;
}

const NEW_QUESTION_TO_MODERATE_PUSHER_EVENT = "App\\Events\\NewQuestionToModerate";
const NEW_QUESTION_TO_ANSWER_PUSHER_EVENT = "App\\Events\\NewQuestionToAnswer";

const subscribeToQAQuestionChannel = (
    { token, appId, episodeId, isChairman, isModerator }: IConfig,
    callback: (status: any) => void
) => {
    const who = isModerator ? "moderator" : isChairman ? "speaker" : "";

    const channel = pusher(token).subscribe(`private-qaa-${who}-${appId}.${episodeId}`);

    if (isChairman) {
        channel.bind(NEW_QUESTION_TO_ANSWER_PUSHER_EVENT, ({ question }: any) => {
            callback(question);
        });
    }

    if (isModerator) {
        channel.bind(NEW_QUESTION_TO_MODERATE_PUSHER_EVENT, ({ question }: any) => {
            callback(question);
        });
    }

    return channel;
};

export default subscribeToQAQuestionChannel;
