import { Navigate, Outlet } from "react-router-dom";
import { LOGIN } from "./routes";

interface IProtectedRoute {
    isSignedIn: boolean;
}

const ProtectedRoutes: React.FC<IProtectedRoute> = ({ isSignedIn }) => {
    if (!isSignedIn) {
        return <Navigate to={LOGIN} replace />;
    }

    return <Outlet />;
};

export default ProtectedRoutes;
