import { IAnswerQuiz, IQuizCategory, QA } from "@adm-media/adam-client";
import {
    createAsyncThunk,
    createDraftSafeSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { serviceApi } from "../../utils/serviceApi";
import { RootState } from "..";

const QAClient = new QA();

export interface LoadingState {
    loading: boolean;
    success: boolean;
    error: any;
}

export interface IQASubmitted {
    updated_at: string;
    id: number;
    edited_content: string;
    subscriber: {
        firstname: string;
        lastname: string;
        email: string;
    };
    original_content: string;
    created_at: string;
}

export type IQAReducer = {
    newQuestion: LoadingState;
    updateQuestion: LoadingState;
    markAsReadQuestion: LoadingState;
    questions: {
        items: any[];
        selected: any[];
        submitted: IQASubmitted[];
        loading: boolean;
        error: any;
    };
    evaluationQuestions: {
        items: any[];
        loading: boolean;
        error: any;
    };
    quizCategories: {
        items: IQuizCategory[];
        loading: boolean;
        error: any;
    };
    answerQuestion: {
        status: any;
        loading: boolean;
        error?: any;
        success?: boolean;
        correctAnswers: number;
        availableTry?: number | null;
        message?: string;
        quizAnswered?: {
            [key: string]: { isAnswered: boolean };
        };
    };
};

const sendQuestion = createAsyncThunk<any, any>(
    `qa/sendQuestion`,
    async (values, thunkApi) => {
        const {
            app: { id },
            user: { token },
        } = thunkApi.getState() as RootState;
        if (!id) throw Error("id does not exist");
        if (!token) throw Error("Token not exist");
        const headers = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const api = await QAClient.sendQuestion(id, values, headers);
        const res = await serviceApi<typeof api>(api);
        return res.data.data;
    }
);

const updateQuestionService = createAsyncThunk<any, any>(
    `qa/updateQuestion`,
    async ({ questionId, ...values }, thunkApi) => {
        const {
            app: { id },
            user: { token },
        } = thunkApi.getState() as RootState;
        if (!id) throw Error("id does not exist");
        if (!token) throw Error("Token not exist");

        const headers = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const api = await QAClient.updateQuestion(
            id,
            questionId,
            values,
            headers
        );
        const res = await serviceApi<typeof api>(api);
        return res.data.data;
    }
);

const markAsReadQuestionService = createAsyncThunk<any, any>(
    `qa/markAsReadQuestion`,
    async (questionId, thunkApi) => {
        const {
            app: { id },
            user: { token },
        } = thunkApi.getState() as RootState;
        if (!id) throw Error("id does not exist");
        if (!token) throw Error("Token not exist");

        const headers = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const api = await QAClient.markAsReadQuestion(id, questionId, headers);
        const res = await serviceApi<typeof api>(api);
        return res.data.data;
    }
);

const fetchQuestions = createAsyncThunk<any, any>(
    `qa/fetchQuestions`,
    async (episodeId, thunkApi) => {
        const {
            app: { id },
            user: { token },
        } = thunkApi.getState() as RootState;
        if (!id) throw Error("id does not exist");
        if (!token) throw Error("Token not exist");

        const headers = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const api = await QAClient.fetchQuestions(id, episodeId, headers);
        const res = await serviceApi<typeof api>(api);
        return res.data.data;
    }
);

const fetchEvaluationQuestions = createAsyncThunk<any, any>(
    `qa/fetchEvaluationQuestions`,
    async (episodeId, thunkApi) => {
        const {
            app: { id },
            user: { token },
        } = thunkApi.getState() as RootState;
        if (!id) throw Error("id does not exist");
        if (!token) throw Error("Token not exist");

        const headers = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const api = await QAClient.fetchEvaluationQuestions(
            id,
            episodeId,
            headers
        );
        const res = await serviceApi<typeof api>(api);
        return res.data.data;
    }
);

const fetchQuizCategories = createAsyncThunk<any, any>(
    `qa/fetchQuizCategories`,
    async (episodeId, thunkApi) => {
        const {
            app: { id },
            user: { token },
        } = thunkApi.getState() as RootState;
        if (!id) throw Error("id does not exist");
        if (!token) throw Error("Token not exist");

        const headers = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const api = await QAClient.fetchQuizCategories(id, episodeId, headers);
        const res = await serviceApi<typeof api>(api);
        return res.data.data;
    }
);

const answerQuiz = createAsyncThunk<any, any>(
    `qa/answerQuiz`,
    async (payload: IAnswerQuiz, thunkApi) => {
        const {
            app: { id },
            user: { token },
        } = thunkApi.getState() as RootState;
        if (!id) throw Error("id does not exist");
        if (!token) throw Error("Token not exist");

        const headers = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const api = await QAClient.answerQuiz(id, payload, headers);
        const res = await serviceApi<typeof api>(api);
        return res.data.data;
    }
);

const initialState: IQAReducer = {
    newQuestion: {
        loading: false,
        success: false,
        error: undefined,
    },
    updateQuestion: {
        loading: false,
        success: false,
        error: undefined,
    },
    markAsReadQuestion: {
        loading: false,
        success: false,
        error: undefined,
    },
    questions: {
        items: [],
        selected: [],
        submitted: [],
        loading: false,
        error: undefined,
    },
    evaluationQuestions: {
        items: [],
        loading: false,
        error: undefined,
    },
    quizCategories: {
        items: [],
        loading: false,
        error: undefined,
    },
    answerQuestion: {
        success: false,
        status: undefined,
        loading: false,
        error: undefined,
        correctAnswers: 0,
        availableTry: undefined,
        message: undefined,
        quizAnswered: {} as any,
    },
};

const qaSlice = createSlice({
    name: "qa",
    initialState,
    reducers: {
        resetNewQuestion: (state) => {
            state.newQuestion.loading = false;
            state.newQuestion.success = false;
        },
        addSelectedQuestion: (state, { payload }) => {
            state.questions.selected = [...state.questions.selected, payload];
        },
        removeSelectedQuestion: (state, { payload }) => {
            const selected = [...state.questions.selected];
            const index = selected.indexOf(payload);
            if (index > -1) {
                selected.splice(index, 1);
            }
            state.questions.selected = [...selected];
        },
        addQuestion: (state, { payload }) => {
            state.questions.items = [payload, ...state.questions.items];
        },
        addSubmittedQuestion: (state, { payload }) => {
            state.questions.submitted = [payload, ...state.questions.submitted];
        },
        cleanQuizCategories: (state) => {
            state.quizCategories.items = [];
        },
        addListAnsweredQuiz: (state, { payload }) => {
            state.answerQuestion.quizAnswered = {
                ...state.answerQuestion.quizAnswered,
                [payload?.episodeId]: { isAnswered: payload?.isAnswered },
            };
        },
        cleanUpQuiz: (state) => {
            state.answerQuestion.status = false;
            state.answerQuestion.error = undefined;
            state.answerQuestion.message = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(sendQuestion.pending, (state) => {
            state.newQuestion.loading = true;
        });

        builder.addCase(sendQuestion.fulfilled, (state) => {
            state.newQuestion.loading = false;
            state.newQuestion.success = true;
        });

        builder.addCase(sendQuestion.rejected, (state, { payload }) => {
            state.newQuestion.loading = false;
            state.newQuestion.error = payload;
        });

        builder.addCase(
            updateQuestionService.pending,
            (
                state,
                {
                    meta: {
                        arg: { id },
                    },
                }
            ) => {
                state.updateQuestion.loading = id;
            }
        );

        builder.addCase(
            updateQuestionService.fulfilled,
            (state, { payload }) => {
                state.updateQuestion.loading = false;
                state.updateQuestion.success = true;

                const { id: questionId } = payload;

                const items = [...state.questions.items];
                const selected = [...state.questions.selected];

                const filteredItems =
                    items && items.filter((item) => item.id !== questionId);

                const index = selected.indexOf(questionId);
                if (index > -1) {
                    selected.splice(index, 1);
                }
                state.questions.items = [...filteredItems];
                state.questions.selected = [...selected];
                state.questions.submitted = [
                    payload,
                    ...state.questions.submitted,
                ];
            }
        );

        builder.addCase(
            updateQuestionService.rejected,
            (state, { payload }) => {
                state.updateQuestion.loading = false;
                state.updateQuestion.error = payload;
            }
        );

        builder.addCase(fetchQuestions.pending, (state) => {
            state.questions.loading = true;
        });

        builder.addCase(fetchQuestions.fulfilled, (state, { payload }) => {
            state.questions.loading = false;
            state.questions.items = payload;
        });

        builder.addCase(fetchQuestions.rejected, (state, { payload }) => {
            state.questions.loading = false;
            state.questions.error = payload;
        });

        builder.addCase(
            markAsReadQuestionService.pending,
            (state, { meta: { arg } }) => {
                state.markAsReadQuestion.loading = arg;
            }
        );

        builder.addCase(
            markAsReadQuestionService.fulfilled,
            (state, { payload }) => {
                state.markAsReadQuestion.loading = false;
                state.markAsReadQuestion.success = true;

                const { id: questionId } = payload;

                const items = [...state.questions.items];
                const selected = [...state.questions.selected];

                let currentItem = {};
                const filteredItems =
                    items &&
                    items.filter((item) => {
                        if (item.id === questionId) {
                            currentItem = item;
                        }
                        return item.id !== questionId;
                    });

                const index = selected.indexOf(questionId);
                if (index > -1) {
                    selected.splice(index, 1);
                }
                state.questions.items = [...filteredItems];
                state.questions.selected = [...selected];
                state.questions.submitted = [
                    { ...currentItem, ...payload },
                    ...state.questions.submitted,
                ];
            }
        );

        builder.addCase(
            markAsReadQuestionService.rejected,
            (state, { payload }) => {
                state.markAsReadQuestion.loading = false;
                state.markAsReadQuestion.error = payload;
            }
        );

        // fetchEvaluationQuestions
        builder.addCase(fetchEvaluationQuestions.pending, (state) => {
            state.evaluationQuestions.loading = true;
        });

        builder.addCase(
            fetchEvaluationQuestions.fulfilled,
            (state, { payload }) => {
                state.evaluationQuestions.loading = false;
                state.evaluationQuestions.items = payload;
            }
        );

        builder.addCase(
            fetchEvaluationQuestions.rejected,
            (state, { payload }) => {
                state.evaluationQuestions.loading = false;
                state.evaluationQuestions.error = payload;
            }
        );

        // fetchQuizCategories
        builder.addCase(fetchQuizCategories.pending, (state) => {
            state.quizCategories.loading = true;
        });

        builder.addCase(fetchQuizCategories.fulfilled, (state, { payload }) => {
            state.quizCategories.loading = false;
            state.quizCategories.items = payload;
        });

        builder.addCase(fetchQuizCategories.rejected, (state, { payload }) => {
            state.quizCategories.loading = false;
            state.quizCategories.error = payload;
        });

        // Answer quiz
        builder.addCase(answerQuiz.pending, (state) => {
            state.answerQuestion.loading = true;
        });

        builder.addCase(answerQuiz.fulfilled, (state, { payload }) => {
            state.answerQuestion.loading = false;
            state.answerQuestion.status = payload?.status;
            state.answerQuestion.correctAnswers = payload?.correct_answers;
            state.answerQuestion.availableTry = payload?.available_try;
            state.answerQuestion.error = payload?.error;
            state.answerQuestion.message = payload?.message;
        });

        builder.addCase(answerQuiz.rejected, (state, { payload }) => {
            state.answerQuestion.loading = false;
            state.answerQuestion.error = payload;
        });
    },
});

const selectState = (state: RootState) => state;

const selectSelfQa = createDraftSafeSelector(selectState, ({ qa }) => qa);

const selectNewQuestion = createDraftSafeSelector(
    selectSelfQa,
    ({ newQuestion }) => newQuestion
);
const selectIsSendingQuestion = createDraftSafeSelector(
    selectNewQuestion,
    ({ loading }) => loading
);
const selectIsSuccessOnSendingQuestion = createDraftSafeSelector(
    selectNewQuestion,
    ({ success }) => success
);

const selectUpdateQuestion = createDraftSafeSelector(
    selectSelfQa,
    ({ updateQuestion }) => updateQuestion
);
const selectIsUpdatingQuestion = createDraftSafeSelector(
    selectUpdateQuestion,
    ({ loading }) => loading
);

const selectQuestions = createDraftSafeSelector(
    selectSelfQa,
    ({ questions }) => questions
);
const selectIsLoadingQuestions = createDraftSafeSelector(
    selectQuestions,
    ({ loading }) => loading
);

const selectAllQuestions = createDraftSafeSelector(
    selectQuestions,
    ({ items, selected, submitted }) => ({
        items:
            items && items.filter((item: any) => !selected.includes(item.id)),
        selected:
            items &&
            items.filter((item: any) => selected.includes(item.id)).reverse(),
        submitted,
    })
);

const selectMarkAsReadQuestion = createDraftSafeSelector(
    selectSelfQa,
    ({ markAsReadQuestion }) => markAsReadQuestion
);
const selectIsMakingAsReadQuestion = createDraftSafeSelector(
    selectMarkAsReadQuestion,
    ({ loading }) => loading
);

const selectAllEvaluationQuestions = createDraftSafeSelector(
    selectSelfQa,
    ({ evaluationQuestions }) => evaluationQuestions
);

const selectAllQuizCategories = createDraftSafeSelector(
    selectSelfQa,
    ({ quizCategories }) => quizCategories
);

const selectAnswerQuestionStatus = createDraftSafeSelector(
    selectSelfQa,
    ({ answerQuestion }) => answerQuestion
);

const selectAnsweredQuiz = createDraftSafeSelector(
    selectSelfQa,
    ({ answerQuestion }) => answerQuestion?.quizAnswered
);

const selectIsLoadingSubmit = createDraftSafeSelector(
    selectNewQuestion,
    ({ loading }) => loading
);

export const {
    resetNewQuestion,
    addSelectedQuestion,
    removeSelectedQuestion,
    addQuestion,
    addSubmittedQuestion,
    cleanUpQuiz,
    addListAnsweredQuiz,
    cleanQuizCategories,
} = qaSlice.actions;

export {
    sendQuestion,
    updateQuestionService,
    markAsReadQuestionService,
    fetchQuestions,
    selectSelfQa,
    selectNewQuestion,
    selectIsSendingQuestion,
    selectIsSuccessOnSendingQuestion,
    selectUpdateQuestion,
    selectIsUpdatingQuestion,
    selectQuestions,
    selectIsLoadingQuestions,
    selectAllQuestions,
    selectMarkAsReadQuestion,
    selectIsMakingAsReadQuestion,
    qaSlice,
    selectAllEvaluationQuestions,
    selectAllQuizCategories,
    fetchEvaluationQuestions,
    fetchQuizCategories,
    answerQuiz,
    selectAnswerQuestionStatus,
    selectAnsweredQuiz,
    selectIsLoadingSubmit,
};

export default qaSlice.reducer;
