import {
    ILayout,
    Event,
    ILegals,
    IFaq,
    ICountry,
    Status,
    ILanguage,
    IMedia,
    AssociativeArray,
    IEpisodeCategory,
} from "@adm-media/adam-client";
import {
    createAsyncThunk,
    createDraftSafeSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { buildQuery, serviceApi } from "../../utils/serviceApi";
import { objectKeysToCamelCase } from "../../utils/object-keys-to-camel-case";
import { formatLegals } from "../../utils/formatLegals";

export enum HtmlPageParts {
    header_logo_desktop = "header_logo_desktop",
    header_logo_mobile = "header_logo_mobile",
    right_col_background = "right_col_background",
    right_col_logo = "right_col_logo",
    footer_logo_desktop = "footer_logo_desktop",
    footer_logo_mobile = "footer_logo_mobile",
}

export enum Network {
    ONLINE = "ONLINE",
    OFFLINE = "OFFLINE",
}

export type IAppReducer = {
    domain: string;
    locale: string;
    layout:
        | Omit<ILayout, "registrationForm" | "isCompatibleVersion">
        | undefined;
    id: string | undefined;
    loading: boolean;
    maintenance: boolean;
    contents: any;
    error?: object;
    legals: ILegals[];
    faqs: IFaq[];
    countries: ICountry[];
    network: Status;
    langId: string;
    languages: ILanguage[];
    media: IMedia;
    styles: AssociativeArray;
    timezone: string;
    timezoneAbbreviation: string;
    dsTimezoneAbbreviation?: string;
    cookieConsent: any;
    episodeCategories: IEpisodeCategory[];
    title: string;
    defaultEventLanguage: string;
    statusApp: number;
};

const clientEvent = new Event({
    baseUrl: buildQuery(),
});

const fetchLayouts = createAsyncThunk<any>(
    "app/fetchLayouts",
    async (_, thunkApi) => {
        const {
            app: { domain, locale },
        } = thunkApi.getState() as RootState;

        if (!domain) throw Error("Domain does not exist");

        const api = clientEvent.detect(domain, locale);
        const res = await serviceApi<typeof api>(api);
        return res.data.data;
    }
);

const initialState: IAppReducer = {
    domain: "",
    locale: "",
    layout: undefined,
    id: undefined,
    loading: false,
    maintenance: false,
    contents: {} as any,
    error: undefined,
    legals: [],
    faqs: [],
    countries: [],
    network: Network.ONLINE,
    langId: "",
    languages: [],
    media: {} as any,
    styles: {} as any,
    timezone: "",
    timezoneAbbreviation: "",
    dsTimezoneAbbreviation: undefined,
    cookieConsent: null,
    episodeCategories: [],
    title: "",
    defaultEventLanguage: "",
    statusApp: 0,
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setAppDomain: (state, { payload }) => {
            state.domain = payload;
        },
        setAppStatus: (state, { payload }) => {
            state.statusApp = payload;
        },
        changeNetworkState: (state, { payload }) => {
            state.network = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLayouts.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchLayouts.fulfilled, (state, { payload }) => {
            state.loading = false;
            // Layout needed to populate homepage
            state.layout = {
                ui: payload?.theme?.config,
                theme: {
                    ...objectKeysToCamelCase(payload.styles),
                },
            };
            state.contents = payload.contents;
            state.legals = formatLegals(payload.legals);
            state.maintenance = payload.maintenance;
            state.cookieConsent = payload.cookie_consent;
            state.dsTimezoneAbbreviation = payload.ds_timezone_abbreviation;
            // state.faqs = payload.faqs;
            state.id = payload.id;
            state.defaultEventLanguage = payload.default_lang;
            state.locale = payload.locale;
            state.languages = payload.languages;
            state.media = payload.media;
            // TODO - To update once is implemented
            state.statusApp = 2;
            state.styles = payload.styles;
            // Single timezone assigned
            state.timezone = payload.timezone;
            state.timezoneAbbreviation = payload.timezone_abbreviation;
            state.episodeCategories = payload.episodecategories;
            state.title = payload.title;
        });
        builder.addCase(fetchLayouts.rejected, (state, { error }) => {
            state.loading = false;
            state.error = error;
        });
    },
});

const selectState = (state: RootState) => state;

const selectSelfApp = createDraftSafeSelector(selectState, ({ app }) => app);

const selectLayout = createDraftSafeSelector(
    selectSelfApp,
    ({ layout }) => layout
);

const selectHomeLayout = createDraftSafeSelector(
    selectLayout,
    (layout) => layout?.ui
);

const selectMedia = createDraftSafeSelector(selectSelfApp, ({ media }) => {
    return {
        headerLogoDesktop: media[HtmlPageParts.header_logo_desktop]?.url,
        headerLogoMobile: media[HtmlPageParts.header_logo_mobile]?.url,
    };
});

const selectAppId = createDraftSafeSelector(selectSelfApp, ({ id }) => id);

const selectIsLayoutLoading = createDraftSafeSelector(
    selectSelfApp,
    ({ loading }) => loading
);

const selectTimezone = createDraftSafeSelector(
    selectSelfApp,
    ({ timezone }) => timezone
);

const selectTimezoneDS = createDraftSafeSelector(
    selectSelfApp,
    ({ dsTimezoneAbbreviation }) => dsTimezoneAbbreviation
);

const selectTimezoneAbbreviation = createDraftSafeSelector(
    selectSelfApp,
    ({ timezoneAbbreviation }) => timezoneAbbreviation
);

const selectAppStyles = createDraftSafeSelector(
    selectSelfApp,
    ({ styles }) => styles
);

const selectIsMaintenanceMode = createDraftSafeSelector(
    selectSelfApp,
    ({ maintenance }) => maintenance
);

const selectContent = createDraftSafeSelector(
    selectSelfApp,
    ({ contents }) => contents
);

const selectLanguages = createDraftSafeSelector(
    selectSelfApp,
    ({ languages }) => languages
);

const selectDefaultEventLang = createDraftSafeSelector(
    selectSelfApp,
    ({ defaultEventLanguage }) => defaultEventLanguage
);

const selectStatusApp = createDraftSafeSelector(
    selectSelfApp,
    ({ statusApp }) => statusApp
);

const selectNetworkStatus = createDraftSafeSelector(
    selectSelfApp,
    ({ network }) => network
);

// eslint-disable-next-line no-empty-pattern
export const { setAppDomain, setAppStatus, changeNetworkState } =
    appSlice.actions;

export {
    fetchLayouts,
    selectSelfApp,
    selectLayout,
    selectHomeLayout,
    selectMedia,
    selectAppId,
    selectIsLayoutLoading,
    selectTimezone,
    selectAppStyles,
    selectTimezoneDS,
    selectTimezoneAbbreviation,
    selectIsMaintenanceMode,
    selectContent,
    selectLanguages,
    selectDefaultEventLang,
    selectStatusApp,
    selectNetworkStatus,
};

export default appSlice.reducer;
