import React from "react";
import {
    CloseCircleOutlined,
    HomeFilled,
    InfoCircleFilled,
    PlusCircleFilled,
    ProfileFilled,
    QuestionCircleFilled,
    SettingFilled,
} from "@ant-design/icons";
import "./icons.scss";
import classNames from "classnames";

export type SizeIcon = "small" | "medium" | "large";

export interface IIcon {
    className?: string;
    sizeIcon?: SizeIcon;
    isActive?: boolean;
}

const HomeIcon: React.FC<IIcon> = (props) => (
    <HomeFilled className={classNames(props.sizeIcon)} {...props} />
);
const InfoIcon: React.FC<IIcon> = (props) => (
    <InfoCircleFilled className={classNames(props.sizeIcon)} {...props} />
);
const QAIcon: React.FC<IIcon> = (props) => (
    <QuestionCircleFilled className={classNames(props.sizeIcon)} {...props} />
);
const EvaluationIcon: React.FC<IIcon> = (props) => (
    <ProfileFilled className={classNames(props.sizeIcon)} {...props} />
);
const AddIcon: React.FC<IIcon> = (props) => (
    <PlusCircleFilled className={classNames(props.sizeIcon)} {...props} />
);
const CloseModalIcon: React.FC<IIcon> = (props) => (
    <CloseCircleOutlined className={classNames(props.sizeIcon)} {...props} />
);
const SettingsIcons: React.FC<IIcon> = (props) => (
    <SettingFilled className={classNames(props.sizeIcon)} {...props} />
);

export {
    HomeIcon,
    InfoIcon,
    QAIcon,
    EvaluationIcon,
    AddIcon,
    CloseModalIcon,
    SettingsIcons,
};
