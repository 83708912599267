import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

export type IModalsReducer = {
    modal: string | null;
    props?: any;
};

export const initialState: IModalsReducer = {
    modal: null,
    props: {},
};

const modalsSlice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        showModal: (state, { payload }) => {
            state.modal = payload.modal;
            state.props = payload.props;
        },
        closeModal: (state) => {
            state.modal = null;
            state.props = {};
        },
    },
});

const selectState = (state: RootState) => state;

const selectSelf = createDraftSafeSelector(selectState, ({ modals }) => modals);
const selectCurrentModal = createDraftSafeSelector(
    selectSelf,
    ({ modal, props }) => ({
        modal,
        props,
    })
);

export const { showModal, closeModal } = modalsSlice.actions;

export { selectSelf, selectCurrentModal, modalsSlice };

export default modalsSlice.reducer;
