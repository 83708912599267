import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { useSelector } from "react-redux";

const useAppDispatch = () => {
    const dispatch = useDispatch<AppDispatch>();

    return { dispatch, useSelector };
};

export default useAppDispatch;
