import React from "react";
import Page from "../../components/ui/Page";

const Chairman: React.FC = () => {
    return (
        <Page>
            <p>Chairman</p>
        </Page>
    );
};

export default Chairman;
