import AnswerPollModal from "./AnswerPoll/AnswerPoll.modal";
import QAModal from "./QA/QA.modal";
import QAManageQuestion from "./QAManageQuestion/QAManageQuestion";

export const ANSWERPOLL = "ANSWERPOLL";
export const QA_MODAL = "QA_MODAL";
export const QA_QUESTION_MODAL = "QA_QUESTION_MODAL";

const availableModals: any = {
    [ANSWERPOLL]: AnswerPollModal,
    [QA_MODAL]: QAModal,
    [QA_QUESTION_MODAL]: QAManageQuestion,
};

export default availableModals;
