export const theme1 = {
    home: {
        config: {
            props: [],
            children: [
                {
                    props: {},
                    component: "Page",
                    children: [
                        {
                            props: {},
                            component: "Agenda",
                        },
                    ],
                },
            ],
            component: "Page",
        },
    },
    info: {
        config: {
            props: [],
            children: [{ props: {}, component: "TextBlock", children: [] }],
            component: "Page",
        },
    },
    evaluationForm: {
        config: {
            props: [],
            children: [
                { props: {}, component: "EvaluationForm", children: [] },
            ],
            component: "Page",
        },
    },
    qa: {
        config: {
            props: {},
            component: "QAForm",
            children: [],
        },
    },
    drawer: {
        config: {
            props: {},
            component: "SelectLanguage",
            children: [],
        },
    },
    version: 2,
};
