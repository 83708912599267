import availableModals from "./index";

interface IModalProvider {
    modal: string;
    props: any;
}

/**
 * You can access props in modal component like so 
 * `
    const LoginModal: React.FC = (props) => {}
 `
 */
const ModalsProvider: React.FC<IModalProvider> = ({ modal, props }) => {
    return availableModals[modal] ? availableModals[modal](props) : "";
};

export default ModalsProvider;
