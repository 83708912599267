const getDomain = (): string => {
    let domain = process.env.REACT_APP_DOMAIN;
    const url = new URL(window.location.href);
    domain = url.searchParams.get("domain") || domain;

    if (process.env.NODE_ENV === "production") {
        domain = window.location.hostname;
    }

    return domain || "";
};

export default getDomain;
