import classNames from "classnames";
import "./Page.scss";

type IPage = {
    children: React.ReactNode;
    className?: string;
};

/**
 * Used for wrapping single page feature
 * @param param0
 * @returns
 */
const Page: React.FC<IPage> = ({ children, className }) => {
    return (
        <div
            data-testid="page-wrapper"
            className={classNames("page-wrapper", className)}
        >
            {children}
        </div>
    );
};

export default Page;
