import {
    createAsyncThunk,
    createDraftSafeSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { AuthType, IAnswer, IEpisode, User } from "@adm-media/adam-client";
import { serviceApi } from "../../utils/serviceApi";
import { RootState } from "..";
import { clearToken, setToken } from "../../utils/auth";

const userClient = new User();

export interface RequestAction {
    loading: boolean;
    success?: boolean;
    sent?: boolean;
    error: any;
    payload?: any;
    registerSuccess?: boolean;
    status?: any;
}

// TODO - review
export interface IIdentity {
    answers: IAnswer[] | [];
    country_id: string;
    email: string;
    episodes: IEpisode[] | [];
    event: string;
    firstname?: string;
    is_moderator: boolean;
    is_chairman: boolean;
    id: number;
    is_internal: number;
    is_staff: boolean;
    is_suppressed: number;
    lang_id: string;
    lastname: string;
}

export type IUserReducer = {
    identity: IIdentity;
    token?: string;
    isInternal: boolean;
    selectedEpisode: number | undefined;
    loading: boolean;
    error?: any;
    login: RequestAction;
    logout: RequestAction;
    register: RequestAction;
    closeActiveSessionReq: RequestAction;
    answerEvaluationForm: RequestAction;
    identityEpisodes: IEpisode[];
    updateProfile: RequestAction;
    userInfo: {
        loading: boolean;
        data: IIdentity;
        error: any;
    };
};

const authThunk = (type: AuthType) =>
    createAsyncThunk<any, any | undefined>(
        `app/${type}`,
        async (values, { getState }) => {
            try {
                const {
                    app: { id },
                    user: { token },
                } = getState() as RootState;
                if (!id || !type) throw Error("Id does not exist");

                const headers =
                    type === "logout"
                        ? {
                              headers: {
                                  Authorization: `Bearer ${token}`,
                              },
                          }
                        : undefined;

                const api = userClient.doAuthAction(id, type, values, headers);
                const res = await serviceApi<typeof api>(api);

                return res.data.data;
            } catch (error) {
                return error;
            }
        }
    );

const getMe = createAsyncThunk<any>(`user/getMe`, async (_, thunkApi) => {
    const {
        app: { id },
        user: { token },
    } = thunkApi.getState() as RootState;

    if (token) {
        if (!id) throw Error("Id does not exist");

        const headers = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const api = userClient.getMe(id, headers);
        const res = await serviceApi<typeof api>(api);
        return res.data.data;
    }

    return thunkApi.rejectWithValue({
        code: 401,
    });
});

const closeActiveSession = createAsyncThunk<any, any>(
    `user/closeActiveSession`,
    async (values, thunkApi) => {
        const {
            app: { id },
        } = thunkApi.getState() as RootState;
        if (!id) throw Error("Id does not exist");

        const api = userClient.closeActiveSession(id, values);
        const res = await serviceApi<typeof api>(api);
        return res.data.data;
    }
);

const answerEvaluationFormService = createAsyncThunk<any, any>(
    `user/answerEvaluationForm`,
    async (values, thunkApi) => {
        const {
            user: { token },
            app: { id },
        } = thunkApi.getState() as RootState;
        if (!id) throw Error("Id does not exist");
        if (!token) throw Error("Token not exist");

        const headers = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const api = userClient.answerEvaluationForm(id, values, headers);
        const res = await serviceApi<typeof api>(api);
        return res.data.data;
    }
);

const registerUser = authThunk("register");
const loginUser = authThunk("login");
const logoutUser = authThunk("logout");

const initialState: IUserReducer = {
    identity: {} as any,
    // List of personal episodes, make user see their episodes assigned for the event if they logout in the homepage
    identityEpisodes: {} as any,
    isInternal: false,
    selectedEpisode: undefined,
    token: undefined,
    loading: false,
    error: undefined,
    userInfo: {
        loading: false,
        data: {} as any,
        error: undefined,
    },

    login: {
        loading: false,
        error: undefined,
        payload: undefined,
    },
    logout: {
        loading: false,
        error: undefined,
    },
    register: {
        loading: false,
        error: undefined,
        registerSuccess: false,
    },
    closeActiveSessionReq: {
        loading: false,
        error: undefined,
    },
    answerEvaluationForm: {
        loading: false,
        success: false,
        sent: false,
        error: undefined,
    },
    updateProfile: {
        loading: false,
        status: false,
        error: undefined,
        payload: {} as any,
    },
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        changeIsInternalUser: (state, { payload }) => {
            state.isInternal = payload;
        },
        stickToken: (state, { payload }) => {
            state.token = payload;
        },
        setSelectedEpisode: (state, { payload }) => {
            state.selectedEpisode = payload;
        },
        clearErrorAnswerEvaluation: (state) => {
            state.answerEvaluationForm.error = undefined;
        },
        setAnswerEvaluationSent: (state) => {
            state.answerEvaluationForm.sent = true;
        },
        resetAnswerEvaluationSent: (state) => {
            state.answerEvaluationForm.sent = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(registerUser.pending, (state) => {
            state.register.loading = true;
        });

        builder.addCase(registerUser.fulfilled, (state, { payload }) => {
            state.register.loading = false;
            if (payload.me) {
                state.register.registerSuccess = true;
            }
        });

        builder.addCase(registerUser.rejected, (state, { error }) => {
            state.register.loading = false;
            state.register.error = error;
        });

        builder.addCase(loginUser.pending, (state) => {
            state.login.loading = true;
        });

        builder.addCase(loginUser.fulfilled, (state, { payload }) => {
            state.login.loading = false;

            // Handle payload errors
            if (
                payload?.response?.status === 403 ||
                payload?.response?.status === 401 ||
                payload?.response?.status === 422 ||
                payload?.response?.status === 500
            ) {
                state.login.loading = false;
                state.login.payload = payload;
                return;
            }

            state.login.payload = payload;
            state.identity = payload?.me;
            state.identityEpisodes = state.identity?.episodes;
            state.token = payload?.token;
            setToken(payload?.token);
        });

        builder.addCase(loginUser.rejected, (state, { error }) => {
            state.login.loading = false;
            state.login.error = error;
        });

        builder.addCase(logoutUser.pending, (state) => {
            state.logout.loading = true;
        });

        builder.addCase(logoutUser.fulfilled, (state) => {
            state.logout.loading = false;
            state.token = undefined;
            state.identity = {} as any;
            clearToken();
        });

        builder.addCase(logoutUser.rejected, (state) => {
            state.logout.loading = false;
            state.token = undefined;
            state.identity = {} as any;
            clearToken();
        });

        // Getme
        builder.addCase(getMe.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getMe.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.identity = payload;
        });

        builder.addCase(getMe.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.token = undefined;

            clearToken();
        });

        // Active sessions
        builder.addCase(closeActiveSession.pending, (state) => {
            state.closeActiveSessionReq.loading = true;
        });

        builder.addCase(closeActiveSession.fulfilled, (state) => {
            state.closeActiveSessionReq.loading = false;
        });

        builder.addCase(closeActiveSession.rejected, (state, { payload }) => {
            state.closeActiveSessionReq.loading = false;
            state.closeActiveSessionReq.error = payload;
        });

        // AnswerEvaluationFormService
        builder.addCase(answerEvaluationFormService.pending, (state) => {
            state.answerEvaluationForm.loading = true;
        });

        builder.addCase(
            answerEvaluationFormService.fulfilled,
            (state, { payload }) => {
                state.answerEvaluationForm.loading = false;
                state.answerEvaluationForm.sent = true;
                state.answerEvaluationForm.success = true;
                state.identity.answers = payload?.answers;
            }
        );
        builder.addCase(
            answerEvaluationFormService.rejected,
            (state, { payload }) => {
                state.answerEvaluationForm.loading = false;
                state.answerEvaluationForm.success = false;
                state.answerEvaluationForm.error = payload;
            }
        );
    },
});

const selectState = (state: RootState) => state;

const selectSelfUser = createDraftSafeSelector(
    selectState,
    (state) => state.user
);

const selectUser = createDraftSafeSelector(
    selectSelfUser,
    ({ identity, token, loading, error, selectedEpisode, login }) => ({
        identity,
        token,
        loading,
        error,
        isModerator: identity?.is_moderator,
        isChairman: identity?.is_chairman,
        selectedEpisode,
        login,
    })
);

const selectIsInternalUser = createDraftSafeSelector(
    selectSelfUser,
    ({ isInternal }) => isInternal
);

const selectUserAnswers = createDraftSafeSelector(
    selectSelfUser,
    ({ identity }) => identity?.answers
);

const selectUserEpisodes = createDraftSafeSelector(
    selectSelfUser,
    ({ identity }) => identity?.episodes
);

const selectLogin = createDraftSafeSelector(
    selectSelfUser,
    ({ login }) => login
);

const selectUpdateUserLoading = createDraftSafeSelector(
    selectSelfUser,
    ({ updateProfile }) => updateProfile.loading
);

const selectUpdateUserStatus = createDraftSafeSelector(
    selectSelfUser,
    ({ updateProfile }) => updateProfile.status
);

const selectUserLoginError = createDraftSafeSelector(
    selectSelfUser,
    ({ login }) => login.error
);

const selectIsUserLoginLoading = createDraftSafeSelector(
    selectSelfUser,
    ({ login }) => login.loading
);

const selectLoginPayload = createDraftSafeSelector(
    selectSelfUser,
    ({ login }) => login.payload
);

export const {
    changeIsInternalUser,
    stickToken,
    clearErrorAnswerEvaluation,
    setSelectedEpisode,
    setAnswerEvaluationSent,
    resetAnswerEvaluationSent,
} = userSlice.actions;

export {
    authThunk,
    getMe,
    closeActiveSession,
    answerEvaluationFormService,
    registerUser,
    loginUser,
    logoutUser,
    selectSelfUser,
    selectUser,
    selectIsInternalUser,
    selectUserAnswers,
    selectUserEpisodes,
    selectLogin,
    userSlice,
    selectUpdateUserLoading,
    selectUpdateUserStatus,
    selectUserLoginError,
    selectIsUserLoginLoading,
    selectLoginPayload,
};

export default userSlice.reducer;
