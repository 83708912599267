import { Modal as AntModal, ModalProps } from "antd";
import { closeModal } from "../../store/reducers/modalsReducer";
import Row from "../ui/Row";
import Col from "../ui/Col";
import { CloseModalIcon } from "../ui/icons";
import Spinner from "../Spinner/Spinner";
import classNames from "classnames";
import useAppDispatch from "../../hooks/useAppDispatch";
import "./Modal.scss";

export interface IModalProps extends ModalProps {
    header?: React.ReactNode;
    body: React.ReactNode;
    footer?: React.ReactNode;
    isLoading?: boolean;
}

/**
 * Modal component, as default we provide the close modal dispatch to apply dry
 * @param param0
 * @returns
 */
const Modal: React.FC<IModalProps> & { destroyAll?: () => void } = ({
    body,
    isLoading,
    className,
    ...props
}) => {
    const { dispatch } = useAppDispatch();
    const header = <>header</>;
    const footer = <>footer</>;

    const handleCloseModal = () => dispatch(closeModal());

    return (
        <AntModal
            className={classNames("modal-main", className)}
            onCancel={handleCloseModal}
            destroyOnClose={true}
            closeIcon={<CloseModalIcon />}
            footer={null}
            mask={true}
            {...props}
        >
            {isLoading ? (
                <Spinner withWrapper={true} />
            ) : (
                <>
                    {header ?? (
                        <Row className="modal_header">
                            <Col span={24}>{header}</Col>
                        </Row>
                    )}
                    {body ?? (
                        <Row className="modal_body">
                            <Col span={24}>{body}</Col>
                        </Row>
                    )}
                    {footer ?? (
                        <Row className="modal_footer">
                            <Col span={24}>{footer}</Col>
                        </Row>
                    )}
                </>
            )}
        </AntModal>
    );
};

export default Modal;
