import { initReactI18next } from "react-i18next";
import i18next from "i18next";

//Import all translation files
import english from "./lang/en.json";

const resources = {
    en: {
        translation: english,
    },
};

i18next.use(initReactI18next).init({
    resources,
    fallbackLng: "en",
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

export default i18next;
