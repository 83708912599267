// https://stackoverflow.com/a/65243034
export async function serviceApi<T extends E, E = any>(
    func: T
): Promise<T | any> {
    const promise = await Promise.resolve(func).then((value: T) => {
        return value;
    });

    return promise;
}

export function buildQuery(): string {
    if (!process.env.REACT_APP_BASE_URL || !process.env.REACT_APP_API_VERSION)
        return "";

    return `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_BASEPATH}`;
}
