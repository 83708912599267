import React from "react";
import { Progress as AntLinearProgress, ProgressProps } from "antd";

interface IProgress extends ProgressProps {}

const LinearProgress: React.FC<IProgress> = ({ ...props }) => {
    return <AntLinearProgress data-testid="linear-progress" {...props} />;
};

export default LinearProgress;
