import React, { useEffect, useState } from "react";
import LinearProgress from "../linearProgress/LinearProgress";
import { valueType } from "antd/es/statistic/utils";
import useForceUpdate from "../../hooks/useForceUpdate";
import { closeModal } from "../../store/reducers/modalsReducer";
import classNames from "classnames";
import useAppDispatch from "../../hooks/useAppDispatch";
import "./BarCountdown.scss";

interface BarCountdownProps {}

const BarCountdown: React.FC<BarCountdownProps> = () => {
    const { dispatch } = useAppDispatch();
    const forceUpdate = useForceUpdate();
    const [percentage, setPercentage] = useState(100);
    const countdown = React.useRef<NodeJS.Timer | null>(null);
    const countdownSeconds = 5000;
    const [valueCountdown] = useState<number>(Date.now() + countdownSeconds);
    const colorStroke = "#45CFDD";

    function getTime(value?: valueType) {
        return new Date(value as valueType).getTime();
    }

    const stopTimer = () => {
        if (countdown.current) {
            clearInterval(countdown.current);
            countdown.current = null;
        }

        dispatch(closeModal());
    };

    /** Trigger timer */
    const syncTimer = () => {
        const timestamp = getTime(valueCountdown);

        if (timestamp >= Date.now()) {
            countdown.current = setInterval(() => {
                forceUpdate();
                setPercentage((preVal) => {
                    const realSeconds = countdownSeconds / 1000;
                    const amount = 100 / realSeconds;
                    return preVal - amount;
                });

                if (timestamp + 1200 < Date.now()) {
                    stopTimer();
                }
            }, 1000);
        }
    };

    useEffect(() => {
        syncTimer();
        return () => {
            if (countdown.current) {
                clearInterval(countdown.current);
                countdown.current = null;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueCountdown]);

    return (
        <LinearProgress
            className={classNames("bar-countdown")}
            percent={percentage}
            strokeColor={colorStroke}
            format={() => {
                // Remove the %
                return <></>;
            }}
        />
    );
};

export default BarCountdown;
