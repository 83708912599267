import React from "react";
import Modal from "../Modal";
import classNames from "classnames";
import BarCountdown from "../../countdown/BarCountdown";
import Row from "../../ui/Row";
import Col from "../../ui/Col";

interface AnswerPollModalProps {}

const AnswerPollModal: React.FC<AnswerPollModalProps> = (props) => {
    const bodyModal = (
        <Row justify={"center"}>
            <Col span={24}>
                <BarCountdown />
            </Col>
            <Col span={24}>
                <p>aaa</p>
            </Col>
        </Row>
    );

    return (
        <Modal
            data-testid="answer-poll"
            open
            centered
            className={classNames("answerpoll-modal")}
            body={bodyModal}
            closable={false}
            closeIcon={<></>}
            keyboard={false}
            maskClosable={false}
        />
    );
};

export default AnswerPollModal;
