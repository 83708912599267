import React from "react";
import { IQuestionSubmitValues } from "@adm-media/adam-client";
import { t } from "i18next";
import {
    addSelectedQuestion,
    removeSelectedQuestion,
    updateQuestionService,
} from "../../store/reducers/qaReducer";
import useAppDispatch from "../../hooks/useAppDispatch";
import Page from "../../components/ui/Page";
import QuestionsBoard from "../../components/ui/questionsBoard/QuestionsBoard";

const Moderator: React.FC = () => {
    const { dispatch } = useAppDispatch();

    const cols = [
        { title: t("questionsFromUsers") },
        { title: t("selectedQuestions") },
        { title: t("forwardedToTheChairman") },
    ];

    const handleSelectQuestion = (id: number) =>
        dispatch(addSelectedQuestion(id));
    const handleCancelQuestionSelection = (id: number) =>
        dispatch(removeSelectedQuestion(id));
    const handleSubmit = (payload: IQuestionSubmitValues) => {
        const questionId = payload.id;
        const editedContent = payload.edited_content;
        const confirmed = payload.confirmed;
        dispatch(
            updateQuestionService({
                questionId,
                edited_content: editedContent,
                confirmed,
            })
        );
    };

    return (
        <Page>
            <div data-testid="moderator">
                <QuestionsBoard
                    onSelect={handleSelectQuestion}
                    onCancelSelection={handleCancelQuestionSelection}
                    onSubmit={handleSubmit}
                    cols={cols}
                    withAddNew
                />
            </div>
        </Page>
    );
};

export default Moderator;
