import React from "react";
import { Segmented as AntSegmented, SegmentedProps } from "antd";

interface ISegmented extends Omit<SegmentedProps, "ref"> {}

const Segmented: React.FC<ISegmented> = ({ ...props }) => {
    return <AntSegmented data-testid="segmented" {...props} />;
};

export default Segmented;
