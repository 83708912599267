import {
    IQuestion,
    IQuizCategory,
    IQuizQuestion,
} from "@adm-media/adam-client";

const normalizeEvaluationQuestion = (questions: IQuestion[]) => {
    const getEvaluationType = (
        maxAllowedChoice: number,
        type: number
    ): string => {
        if (type === 0) {
            return "textarea";
        }

        if (maxAllowedChoice > 1) {
            return "checkbox";
        } else {
            return "radio";
        }
    };

    return questions.map(
        ({
            id,
            is_radio: isRadio,
            legend,
            content,
            question_type: type,
            options,
            is_required: isRequired,
            max_allowed_choices: maxAllowedChoice,
        }: any) => ({
            name: id,
            options,
            legend,
            // ! This is a must for the mandatory asterisk - see
            label: isRequired ? content + " *" : content,
            // type: isRadio ? "radio" : type === 1 ? "select" : "textarea",
            type: getEvaluationType(maxAllowedChoice, type),
            isRequired: Boolean(isRequired),
            mode: maxAllowedChoice > 1 ? "multiple" : undefined,
        })
    );
};

/**
 * Format evaluation questions
 * @param questions
 * @returns
 */
const normalizeEvaluationQuestions = (questions: any) =>
    questions?.map((question: any) => ({
        title: question.title,
        inputs: normalizeEvaluationQuestion(question.evaluationquestions),
    }));

const normalizeSingleQuizQuestion = (singleQuizQuestions: IQuizQuestion[]) =>
    singleQuizQuestions.map(
        ({
            id,
            is_radio: isRadio,
            legend,
            content,
            question_type: type,
            options,
            is_required: isRequired,
            max_allowed_choices: maxAllowedChoice,
        }) => ({
            name: id,
            options,
            legend,
            label: content,
            type: isRadio ? "radio" : type === 1 ? "select" : "textarea",
            isRequired: Boolean(isRequired),
            mode: maxAllowedChoice > 1 ? "multiple" : undefined,
        })
    );

const normalizeQuizQuestions = (quizs: IQuizCategory[]) => {
    quizs?.map((singlequiz) => ({
        title: singlequiz.title,
        inputs: normalizeSingleQuizQuestion(singlequiz.questions),
        id: singlequiz.id,
    }));
};

export {
    normalizeEvaluationQuestion,
    normalizeEvaluationQuestions,
    normalizeQuizQuestions,
};
