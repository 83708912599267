import { Drawer as AntDrawer, DrawerProps } from "antd";
import useAppDispatch from "../../hooks/useAppDispatch";
import { closedrawer } from "../../store/reducers/drawerReducer";
import { useTranslation } from "react-i18next";
import { Suspense } from "react";
import Spinner from "../Spinner/Spinner";
import Layout from "../../config/Layout";
import { theme1 } from "../../test/__mocks__/theme1";

interface IDrawer extends DrawerProps {}

const Drawer: React.FC<IDrawer> = ({ ...props }) => {
    const { dispatch } = useAppDispatch();
    const { t } = useTranslation();

    const spinner = <Spinner isFullPage={true} />;

    return (
        <AntDrawer
            title={t("settings")}
            placement="right"
            onClose={() => dispatch(closedrawer())}
            open={false}
            data-testid="drawer"
            {...props}
        >
            <Suspense fallback={spinner}>
                <div data-testid="qa-form-wrapper">
                    <Layout nodes={theme1.drawer.config || []} />
                </div>
            </Suspense>
        </AntDrawer>
    );
};

export default Drawer;
