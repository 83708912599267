import React from "react";
import render from "./render";

// BE incoming prop node
export interface INode {
    component: string;
    props: any;
    children?: INode | INode[] | string;
}

export interface LayoutProps {
    nodes: INode[] | INode;
}

/**
 * Layout directly imported in Homepage
 * @nodes JSON Layout
 * @returns
 */
const Layout: React.FC<LayoutProps> = ({ nodes }) => {
    return <>{render(nodes)}</>;
};

export default Layout;
