import useAppDispatch from "../../hooks/useAppDispatch";
import {
    selectPickedEpisodes,
    selectIsEpisodeLoading,
} from "../../store/reducers/episodesReducer";

const EnrichAgendaProps = ({ ...props }) => {
    const { useSelector } = useAppDispatch();
    const isLoading = useSelector(selectIsEpisodeLoading);
    const episode = useSelector(selectPickedEpisodes);

    return {
        isLoading,
        episode,
        ...props,
    };
};

export default EnrichAgendaProps;
