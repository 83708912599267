import { LazyExoticComponent, lazy } from "react";

export type IUI = {
    [key: string]: LazyExoticComponent<any>;
};

const UI: IUI = {
    Col: lazy(() => import("../components/ui/Col")),
    Row: lazy(() => import("../components/ui/Row")),
    NavigationBar: lazy(() => import("../components/navbar/NavigationBar")),
    Drawer: lazy(() => import("../components/drawer/Drawer")),
    Page: lazy(() => import("../components/ui/Page")),
    Agenda: lazy(() => import("../components/agenda/Agenda")),
    Header: lazy(() => import("../components/header/Header")),
    EvaluationPage: lazy(() => import("../pages/Evaluation/EvaluationPage")),
    Info: lazy(() => import("../pages/Info/Info")),
    TextBlock: lazy(() => import("../components/textBlock/TextBlock")),
    EvaluationForm: lazy(
        () => import("../components/ui/evaluationForm/EvaluationForm")
    ),
    QAForm: lazy(() => import("../components/ui/qaForm/QAForm")),
    SelectLanguage: lazy(
        () => import("../components/selectLanguage/SelectLanguage")
    ),
};

export default UI;
