import { createGlobalStyle, DefaultTheme } from "styled-components";
/**
 * Logic assigned with nullish coalescing operator as sometimes components have missing style
 */
const GlobalStyles = createGlobalStyle<DefaultTheme>`
  :root {
    --primary-color: ${({ theme }) => theme?.colorPrimary}; // AKA Brand Color
   
    /** Navigation Bar */
    --navigation-bar-selected-item: ${({ theme }) =>
        theme.navigationBarSelectedItem ?? "var(--primary-color)"};
  }

`;

export default GlobalStyles;
