import { ConfigProvider, message } from "antd";
import { BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    Network,
    changeNetworkState,
    fetchLayouts,
    selectAppId,
    selectAppStyles,
    selectIsLayoutLoading,
    selectIsMaintenanceMode,
    selectNetworkStatus,
} from "./store/reducers/appReducer";
import Drawer from "./components/drawer/Drawer";
import ModalsProvider from "./components/modals/ModalsProvider";
import { selectCurrentModal } from "./store/reducers/modalsReducer";
import Maintenance from "./pages/Maintenance/Maintenance";
import Spinner from "./components/Spinner/Spinner";
import BannerPreviewMode from "./components/ui/banner/BannerPreviewMode";
import { fetchEpisodes } from "./store/reducers/episodesReducer";
import { subscribeToAuthChannel } from "./pusher";
import useRenderAppStatus from "./hooks/useRenderAppStatus";
import useAppDispatch from "./hooks/useAppDispatch";
import GlobalStyles from "./GlobalStyles";
import { ThemeProvider } from "styled-components";
import { selectCurrentDrawer } from "./store/reducers/drawerReducer";
import "./i18nextConf";

const App: React.FC = () => {
    const { dispatch, useSelector } = useAppDispatch();
    const appId = useSelector(selectAppId);
    const styles = useSelector(selectAppStyles);
    const networkStatus = useSelector(selectNetworkStatus);
    const { modal, props } = useSelector(selectCurrentModal);
    const isLayoutLoading = useSelector(selectIsLayoutLoading);
    const isMaintenanceMode = useSelector(selectIsMaintenanceMode);
    const isDrawerOpen = useSelector(selectCurrentDrawer);
    const { renderOnStatus } = useRenderAppStatus();
    const [isAppInit, setIsAppInit] = useState<boolean>(true);
    const isPreviewMode = true;
    const hasDrawer = true;

    const injectListeners = () => {
        window.addEventListener("online", () =>
            dispatch(changeNetworkState(Network.ONLINE))
        );
        window.addEventListener("offline", () =>
            dispatch(changeNetworkState(Network.OFFLINE))
        );
    };

    const fetchResources = async () => {
        await dispatch(fetchLayouts());
        await dispatch(fetchEpisodes());
        // TODO - discuss which parameters are needed to subscribe to auth channel
        // await subscribeToAuthChannel({ config: { appId } });
    };

    /** Triggers pause or play if network is offline/online */
    const checkNetwork = (networkStatus: string) => {
        message.destroy();

        if (networkStatus === Network.OFFLINE) {
            message.error("offline", 3600);
        } else {
            message.info("online");
        }
    };

    useEffect(() => {
        if (appId) return;
        injectListeners();
        fetchResources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appId]);

    /** Check for any newtwork changes */
    useEffect(() => {
        if (isAppInit) {
            setIsAppInit(!isAppInit);
            return;
        }
        checkNetwork(networkStatus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [networkStatus]);

    if (isLayoutLoading) {
        return <Spinner isFullPage={true} isAppLoader={true} />;
    } else
        return (
            <ConfigProvider
                theme={{
                    token: {
                        ...styles,
                    },
                    // Style by default
                    components: {
                        Segmented: {
                            borderRadius: 0,
                            borderRadiusLG: 0,
                            borderRadiusOuter: 0,
                            colorBgLayout: "white",
                            boxShadowTertiary: "none",
                        },
                    },
                }}
            >
                <ThemeProvider theme={styles}>
                    <GlobalStyles />
                    {isMaintenanceMode ? (
                        <Maintenance />
                    ) : (
                        <>
                            <BrowserRouter>
                                {isPreviewMode && <BannerPreviewMode />}
                                {renderOnStatus()}
                            </BrowserRouter>
                            {hasDrawer && <Drawer open={isDrawerOpen} />}
                            {modal && (
                                <ModalsProvider modal={modal} props={props} />
                            )}
                        </>
                    )}
                </ThemeProvider>
            </ConfigProvider>
        );
};

export default App;
