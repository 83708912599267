import { PusherconfigInstance } from "./PusherInit.class";

// interface IConfig {
//     appId: string;
//     token: string;
// }

const CHANGE_QA_ENABLED_STATUS_PUSHER_EVENT =
    "App\\Events\\ChangeEpisodeQaaStatus";

const subscribeToQAChannel = async (callback: (status: any) => void) => {
    // const channel = pusher(token).subscribe(`presence-${appId}.none`);
    const channel = PusherconfigInstance.getChannel();

    if (!channel) return;

    channel.bind(CHANGE_QA_ENABLED_STATUS_PUSHER_EVENT, (status: any) => {
        callback(status);
    });

    return channel;
};

export default subscribeToQAChannel;
