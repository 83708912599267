import { Suspense } from "react";
import Spinner from "../../components/Spinner/Spinner";
import Layout from "../../config/Layout";
import { theme1 } from "../../test/__mocks__/theme1";
import { selectIsLayoutLoading } from "../../store/reducers/appReducer";
import useAppDispatch from "../../hooks/useAppDispatch";

/**
 * Component that helds information like Agenda from Episodes
 * @returns
 */
const Home: React.FC = () => {
    const { useSelector } = useAppDispatch();
    const isLayoutLoading = useSelector(selectIsLayoutLoading);

    const spinner = <Spinner isFullPage={true} />;

    return isLayoutLoading ? (
        spinner
    ) : (
        <Suspense fallback={spinner}>
            <div data-testid="home">
                <Layout nodes={theme1.home.config || []} />
            </div>
        </Suspense>
    );
};

export default Home;
