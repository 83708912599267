import { Affix } from "antd";
import Col from "../ui/Col";
import Row from "../ui/Row";
import classNames from "classnames";
import { useState } from "react";
import {
    selectIsLayoutLoading,
    selectMedia,
    selectStatusApp,
    setAppStatus,
} from "../../store/reducers/appReducer";
import { useTranslation } from "react-i18next";
import Select from "../select/Select";
import { IStatus } from "../../utils/status";
import useAppDispatch from "../../hooks/useAppDispatch";
import { SettingsIcons } from "../ui/icons";
import { showdrawer } from "../../store/reducers/drawerReducer";
import "./Header.scss";

export interface HeaderProps {
    isAffix?: boolean;
    isPreviewMode?: boolean;
    className?: string;
    userid?: string;
}

/**
 * Affix cause a log warning, will be fixed in the future https://github.com/ant-design/ant-design/issues/26136#issuecomment-1330050323
 */
const Header: React.FC<HeaderProps> = ({
    isAffix = false,
    isPreviewMode = false,
    className,
    ...props
}) => {
    const { t } = useTranslation();
    const { dispatch, useSelector } = useAppDispatch();

    const statusApp = useSelector(selectStatusApp);
    const isLayoutLoading = useSelector(selectIsLayoutLoading);
    const [affixed, toggleAffixed] = useState<boolean>(false);
    const mediaLogoHeader = useSelector(selectMedia);
    const withSettings = true;

    const headerClassNames = classNames(
        "header",
        {
            header__affixed: affixed && isAffix,
        },
        className
    );

    const header = (
        <Row
            className={headerClassNames}
            data-testid="header"
            justify={isPreviewMode ? "start" : "space-around"}
            {...props}
        >
            {isPreviewMode ? (
                <>
                    <Row className="preview-wrapper">
                        <Col span={24}>
                            {`${t("user")}: `} <b>{props.userid}</b>
                        </Col>
                        <Col span={24}>
                            {`${t("actionChangeStatus")}: `}{" "}
                            <Select
                                options={[
                                    {
                                        value: IStatus.CLOSED,
                                        label: t("offline"),
                                    },
                                    {
                                        value: IStatus.PRE,
                                        label: t("preOnline"),
                                    },
                                    { value: IStatus.OPEN, label: t("online") },
                                    {
                                        value: IStatus.POST,
                                        label: t("postOnline"),
                                    },
                                ]}
                                defaultValue={statusApp}
                                loading={isLayoutLoading}
                                onChange={(value) =>
                                    dispatch(setAppStatus(value))
                                }
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Col span={24} className="header__content">
                        <div className="header__content__brand-logo-img">
                            <img
                                src={mediaLogoHeader?.headerLogoMobile}
                                alt="brand-logo"
                            />
                        </div>
                        {withSettings && (
                            <div className="header__content__settings">
                                <div
                                    className={classNames("wrapper-icon")}
                                    onClick={() => dispatch(showdrawer())}
                                >
                                    <SettingsIcons />
                                </div>
                            </div>
                        )}
                    </Col>
                </>
            )}
        </Row>
    );
    if (isPreviewMode) return header;

    const handleChangeAffix = (affixed?: boolean) =>
        toggleAffixed(affixed || false);

    return isAffix ? (
        <Affix
            onChange={handleChangeAffix}
            className="affix"
            data-testid="header-affix"
            {...props}
        >
            {header}
        </Affix>
    ) : (
        header
    );
};

export default Header;
