import React, { MouseEventHandler, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import BasicButton from "../../buttons/BasicButton/BasicButton";
import Input from "../../input/Input";
import "./QuestionCard.scss";

export interface QuestionCardProps {
    cancelButtonText?: string;
    date: string;
    editable?: boolean;
    hoverable?: boolean;
    label?: string;
    loading?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
    onDismiss?: () => void;
    onSubmit?: (question: string) => void;
    question: string;
    submitted?: boolean;
    user: string;
    submitButtonText?: string;
    withActions?: boolean;
}

const QuestionCard: React.FC<QuestionCardProps> = ({
    cancelButtonText = "Cancel",
    date,
    editable = false,
    hoverable = false,
    label,
    loading = false,
    onClick,
    onDismiss,
    onSubmit,
    question,
    submitted,
    user,
    submitButtonText = "Send question",
    withActions,
}) => {
    const cardClassNames = classNames("question-card", {
        hoverable,
        editable,
        submitted,
    });

    const [editableQuestion, changeEditableQuestion] = useState(question);

    const handleOnChangeTextArea = (e: any) =>
        changeEditableQuestion(e.target.value);

    const handleSubmit = () => onSubmit && onSubmit(editableQuestion);
    const handleDismiss = () => onDismiss && onDismiss();

    return (
        <div
            role="presentation"
            data-testid="question-card"
            onClick={onClick}
            className={cardClassNames}
        >
            <div className="question-card__header">
                <div>
                    <div className="question-card__avatar">
                        <span>{user[0].toUpperCase()}</span>
                    </div>
                </div>
                <div>
                    <p
                        className="question-card__user"
                        data-testid="question-card-user"
                    >
                        {user}
                    </p>
                    {label && <p className="question-card__label">{label}</p>}
                    <span
                        className="question-card__date"
                        data-testid="question-card-date"
                    >
                        {moment(date).fromNow()}
                    </span>
                </div>
            </div>
            <div
                className="question-card__question"
                data-testid="question-card-message"
            >
                {question}
            </div>
            {editable && (
                <div>
                    <Input.TextArea
                        autoSize={{ minRows: 4, maxRows: 10 }}
                        className="question-card__textarea"
                        onChange={handleOnChangeTextArea}
                        value={editableQuestion}
                    />
                </div>
            )}

            {(editable || withActions) && (
                <div className="question-card__actions">
                    <BasicButton
                        onClick={handleDismiss}
                        className="question-btn"
                    >
                        {cancelButtonText}
                    </BasicButton>
                    <BasicButton
                        className="question-btn submit-question"
                        loading={loading}
                        onClick={handleSubmit}
                    >
                        {submitButtonText}
                    </BasicButton>
                </div>
            )}
        </div>
    );
};

export default QuestionCard;
