import { AssociativeArray } from "@adm-media/adam-client";
import EnrichHomePage from "./enrich-homepage";
import EnrichAgendaProps from "./enrich-agenda";
import EnrichHeader from "./enrich-header";
import EnrichEvaluationForm from "./enrich-evaluation-form";
import EnrichInfoPage from "./enrich-infopage";
import EnrichTextBlock from "./enrich-text-block";
import EnrichQAForm from "./enrich-qa-form";

export interface IPageComponents {
    [key: string]: any;
}

// Enrichment consist on rendering UI component and taking in consideration props value (example in theme.ts - projectUI)
const components: IPageComponents = {
    Home: EnrichHomePage,
    Agenda: EnrichAgendaProps,
    Header: EnrichHeader,
    EvaluationForm: EnrichEvaluationForm,
    Info: EnrichInfoPage,
    TextBlock: EnrichTextBlock,
    QAForm: EnrichQAForm,
};

const propsProvider = (component: string, props: AssociativeArray | any) => {
    if (Object.keys(components).includes(component)) {
        return components[component](props);
    }
    return props;
};

export default propsProvider;
