import Layout from "../../config/Layout";
import { theme1 } from "../../test/__mocks__/theme1";
import { selectIsLayoutLoading } from "../../store/reducers/appReducer";
import Spinner from "../../components/Spinner/Spinner";
import { Suspense } from "react";
import useAppDispatch from "../../hooks/useAppDispatch";

const Info: React.FC = () => {
    const { useSelector } = useAppDispatch();
    const isLayoutLoading = useSelector(selectIsLayoutLoading);

    const spinner = <Spinner isFullPage={true} />;

    return isLayoutLoading ? (
        spinner
    ) : (
        <Suspense fallback={spinner}>
            <div data-testid="info">
                <Layout nodes={theme1.info.config || []} />
            </div>
        </Suspense>
    );
};

export default Info;
