import camelCase from "lodash/camelCase";

export const objectKeysToCamelCase = (obj: any) => {
    const newObject: any = {};

    Object.keys(obj).forEach((key) => {
        newObject[camelCase(key)] = obj[key];
    });

    return newObject;
};
