import { createElement, ReactNode } from "react";
import UI from "./ui";
import propsProvider from "./props-provider";
import { INode } from "./Layout";

/**
 * Method that create HTML for from a JSON
 * @param nodes
 * @returns
 */
const render = (nodes: INode[] | INode) => {
    // Return if there is nothing to map
    if (nodes === undefined) {
        return;
    }

    // Adapt single node for mapping
    if (!Array.isArray(nodes)) {
        nodes = [nodes];
    }

    return nodes.map((node: INode, i: number) => {
        if (node) {
            /**
             * Applying referred props for each node.component found
             */
            const { children: enrichedChildren, ...enrichedProps } =
                propsProvider(node.component, {
                    ...node.props,
                    key: i,
                });

            /**
             * TODO - concept to be discussed, so far we usually want to make classname or other props explicit when building the structure
             */
            // const nodeChildren = typeof node.children === "string" ? node.children : render(node.children as any);
            const nodeChildren = render(node.children as any);

            const children: ReactNode[] = enrichedChildren || nodeChildren;

            return UI[node.component]
                ? createElement(UI[node.component], enrichedProps, children)
                : console.warn(
                      `DEV: Component "${node.component}" is not imported in UI`
                  );
        }

        return undefined;
    });
};

export default render;
