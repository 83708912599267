import { Route, Routes as ReactRoutes } from "react-router-dom";
import Home from "../pages/Home/Home";
import {
    CHAIRMAN,
    EVALUATION_FORM,
    HOME,
    INFO,
    LOGIN,
    MODERATOR,
} from "./routes";
import Info from "../pages/Info/Info";
import EvaluationPage from "../pages/Evaluation/EvaluationPage";
import ProtectedRoutes from "./ProtectedRoutes";
import Login from "../pages/Login/Login";
import useAppDispatch from "../hooks/useAppDispatch";
import { selectUser } from "../store/reducers/userReducer";
import Moderator from "../pages/Moderator/Moderator";
import Chairman from "../pages/Chairman/Chairman";

const Routes = () => {
    // ON and OFF auth mode
    const isAuthModeOn = true;
    const isUserSignedIn = true;
    const { useSelector } = useAppDispatch();
    const { isModerator, isChairman } = useSelector(selectUser);

    const routes = (
        <>
            <Route path={HOME} element={<Home />} />
            <Route path={INFO} element={<Info />} />
            <Route path={EVALUATION_FORM} element={<EvaluationPage />} />
        </>
    );

    return (
        <ReactRoutes>
            {isAuthModeOn ? (
                <>
                    <Route path={LOGIN} element={<Login />} />
                    <Route
                        element={
                            <ProtectedRoutes isSignedIn={isUserSignedIn} />
                        }
                    >
                        {isModerator && (
                            <Route path={MODERATOR} element={<Moderator />} />
                        )}
                        {isChairman && (
                            <Route path={CHAIRMAN} element={<Chairman />} />
                        )}
                        {routes}
                    </Route>
                </>
            ) : (
                routes
            )}
        </ReactRoutes>
    );
};

export default Routes;
