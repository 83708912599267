import React, { useEffect, useMemo, useState } from "react";
import { SegmentedLabeledOption, SegmentedValue } from "antd/es/segmented";
import { EvaluationIcon, HomeIcon, InfoIcon, QAIcon } from "../ui/icons";
import {
    EVALUATION_FORM as EvaluationFormRoute,
    HOME as HomeRoute,
    INFO as InfoRoute,
    QA_ROUTE as QaRoute,
} from "../../routes/routes";
import classNames from "classnames";
import {
    selectEvaluationOpen,
    selectIsEnabledQA,
} from "../../store/reducers/episodesReducer";
import { showModal } from "../../store/reducers/modalsReducer";
import { QA_MODAL } from "../modals";
import Row from "../ui/Row";
import Col from "../ui/Col";
import Segmented from "../segmented/Segmented";
import { useAppNavigation } from "../../hooks/useAppNavigation";
import useAppDispatch from "../../hooks/useAppDispatch";
import "./NavigationBar.scss";

const NavigationBar: React.FC = () => {
    const { dispatch, useSelector } = useAppDispatch();
    const { location, navigate } = useAppNavigation();

    const isAvailableQA = useSelector(selectIsEnabledQA);
    const isEvaluationOpen = useSelector(selectEvaluationOpen);

    const [routeValue, setRouteValue] = useState<SegmentedValue>();

    /**
     * setRouteValue helps to keep UI style when navigate using browser's navigation
     */
    useEffect(() => {
        if (routeValue === location.pathname) return;

        setRouteValue(location.pathname);
    }, [location, routeValue]);

    const openFaqModal = () =>
        dispatch(
            showModal({
                modal: QA_MODAL,
            })
        );

    const options: (SegmentedValue | SegmentedLabeledOption)[] = useMemo(() => {
        const availableOptions = [
            {
                label: (
                    <div className={classNames("wrapper-icon")}>
                        <HomeIcon />
                    </div>
                ),
                value: HomeRoute,
                visible: true,
                disabled: false,
            },
            {
                label: (
                    <div className={classNames("wrapper-icon")}>
                        <InfoIcon />
                    </div>
                ),
                value: InfoRoute,
                visible: true,
                disabled: false,
            },

            {
                label: (
                    <div className={classNames("wrapper-icon")}>
                        <EvaluationIcon />
                    </div>
                ),
                value: EvaluationFormRoute,
                disabled: !isEvaluationOpen, // needs logic
                visible: true,
            },
            {
                label: (
                    <div className={classNames("wrapper-icon")}>
                        <QAIcon />
                    </div>
                ),
                value: QaRoute,
                disabled: !isAvailableQA,
                visible: true,
            },
        ];
        return availableOptions.filter((el) => el.visible);
    }, [isAvailableQA, isEvaluationOpen]);

    const onNavigate = (value: SegmentedValue) => {
        // QA onclick openmodal
        switch (value) {
            case QaRoute:
                openFaqModal();
                break;

            default:
                navigate(`${value}`);
                break;
        }
    };

    const segmented = (
        <Segmented
            className={classNames("navigation-bar__group")}
            block
            options={options}
            onChange={(e) => onNavigate(e)}
            value={routeValue}
        />
    );

    return (
        <Row
            className={classNames("navigation-bar", {
                top: false,
                bottom: true,
            })}
            data-testid="navigation-bar"
        >
            <Col span={24}>{segmented}</Col>
        </Row>
    );
};

export default NavigationBar;
