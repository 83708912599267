import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

export type IDrawersReducer = {
    drawer: boolean;
    props?: any;
};

export const initialState: IDrawersReducer = {
    drawer: false,
    props: {},
};

const drawersSlice = createSlice({
    name: "drawers",
    initialState,
    reducers: {
        showdrawer: (state) => {
            state.drawer = true;
        },
        closedrawer: (state) => {
            state.drawer = false;
        },
    },
});

const selectState = (state: RootState) => state;

const selectSelf = createDraftSafeSelector(
    selectState,
    ({ drawers }) => drawers
);
const selectCurrentDrawer = createDraftSafeSelector(
    selectSelf,
    ({ drawer }) => drawer
);

export const { showdrawer, closedrawer } = drawersSlice.actions;

export { selectSelf, selectCurrentDrawer, drawersSlice };

export default drawersSlice.reducer;
