import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "..";
import { Episode, IEpisode } from "@adm-media/adam-client";
import { buildQuery, serviceApi } from "../../utils/serviceApi";

export type IEpisodesReducer = {
    episodes: IEpisode[];
    loading: boolean;
    error?: object;
    selectedEpisode: IEpisode;
};

export type ICategories = {
    [key: string]: IEpisode;
};

const clientEpisode = new Episode({
    baseUrl: buildQuery(),
});

const fetchEpisodes = createAsyncThunk<IEpisode[]>(
    "episodes/fetchEpisodes",
    async (_, thunkApi) => {
        const {
            app: { id, locale },
        } = thunkApi.getState() as RootState;
        if (!id) throw Error("Id does not exist");

        const api = clientEpisode.fetchEpisodes(id, locale);
        const res = await serviceApi<typeof api>(api);
        return res.data.data;
    }
);

export const initialState: IEpisodesReducer = {
    episodes: [],
    loading: false,
    error: undefined,
    selectedEpisode: {} as any,
};

const episodesSlice = createSlice({
    name: "episodes",
    initialState,
    reducers: {
        changeCurrentEpisodeQAEnableStatus: (state, { payload }) => {
            const { episode_id, qaa_enabled } = payload?.actions[0];

            state.episodes = state.episodes.map((episode: any) => {
                if (episode_id === episode.id) {
                    const key = "qaa_enabled";
                    episode[key] = qaa_enabled;
                }

                return episode;
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEpisodes.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchEpisodes.fulfilled, (state, { payload }) => {
            state.episodes = payload;
            state.episodes = payload;
            state.selectedEpisode = state.episodes[0];
            state.loading = false;
        });

        builder.addCase(fetchEpisodes.rejected, (state, { error }) => {
            state.loading = false;
            state.error = error;
        });
    },
});

const selectState = (state: RootState) => state;

const selectSelfEpisode = createDraftSafeSelector(
    selectState,
    ({ episodes }) => episodes
);

const selectAllEpisodes = createDraftSafeSelector(
    selectSelfEpisode,
    ({ episodes }) => episodes
);
const selectIsEpisodeLoading = createDraftSafeSelector(
    selectSelfEpisode,
    ({ loading }) => loading
);

const selectPickedEpisodes = createDraftSafeSelector(
    selectSelfEpisode,
    ({ selectedEpisode }) => selectedEpisode
);

const selectEvaluationOpen = createDraftSafeSelector(
    selectSelfEpisode,
    ({ selectedEpisode }) => selectedEpisode?.is_evaluation_open
);

const selectIsEnabledQA = createDraftSafeSelector(
    selectPickedEpisodes,
    (episode: IEpisode) => !!episode.qaa_enabled
);

export const { changeCurrentEpisodeQAEnableStatus } = episodesSlice.actions;

export {
    fetchEpisodes,
    selectSelfEpisode,
    selectIsEpisodeLoading,
    episodesSlice,
    selectAllEpisodes,
    selectPickedEpisodes,
    selectEvaluationOpen,
    selectIsEnabledQA,
};

export default episodesSlice.reducer;
