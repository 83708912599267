import {
    PreloadedState,
    combineReducers,
    configureStore,
} from "@reduxjs/toolkit";
import appReducer from "./reducers/appReducer";
import modalsReducer from "./reducers/modalsReducer";
import episodesReducer from "./reducers/episodesReducer";
import evaluationReducer from "./reducers/evaluationReducer";
import qaReducer from "./reducers/qaReducer";
import drawerReducer from "./reducers/drawerReducer";
import userReducer from "./reducers/userReducer";

const rootReducer = combineReducers({
    app: appReducer,
    modals: modalsReducer,
    episodes: episodesReducer,
    evaluationForm: evaluationReducer,
    qa: qaReducer,
    drawers: drawerReducer,
    user: userReducer,
});

const store = configureStore({
    reducer: rootReducer,
    // Deactivate check for big data in dev mode https://stackoverflow.com/q/65217815

    devTools: process.env.NODE_ENV !== "production",
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    });
}

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;
