import React from "react";
import Header from "../../header/Header";
import "./BannerPreviewMode.scss";

interface BannerPreviewModeProps {}

const BannerPreviewMode: React.FC<BannerPreviewModeProps> = () => {
    const userid = "54!DFDHDee";
    return (
        <Header
            data-testid="banner-preview-mode"
            className="banner-preview-mode"
            isPreviewMode={true}
            userid={userid}
        />
    );
};

export default BannerPreviewMode;
