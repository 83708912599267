import { Channel } from "pusher-js";

class PusherConfig {
    channel: undefined | Channel = undefined;

    public setChannel(channel: Channel) {
        this.channel = channel;
    }

    public getChannel() {
        return this.channel;
    }
}

const PusherconfigInstance = new PusherConfig();

export { PusherconfigInstance };
