import { Select as AntSelect, SelectProps } from "antd";

interface ISelect extends SelectProps {}

const Select: React.FC<ISelect> & { Option: typeof AntSelect.Option } = ({
    ...props
}) => {
    return <AntSelect data-testid="select" {...props} />;
};

Select.Option = AntSelect.Option;

export default Select;
