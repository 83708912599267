import { ILegals } from "@adm-media/adam-client";

export const formatLegals = (legals: any): ILegals[] => {
    if (!legals) return [];
    return legals?.map((legal: ILegals) => ({
        type: !!legal.is_checkbox ? "checkbox" : "plaintext",
        label: legal.checkbox_label,
        name: legal.field_name,
        value: !!legal.internal_status,
        required: !!legal.is_required,
        content: legal.content,
        hide_asterisk: legal.hide_asterisk,
    }));
};
