import Countdown from "antd/es/statistic/Countdown";
import React from "react";
import NavigationBar from "../components/navbar/NavigationBar";
import AppHeader from "../components/header/Header";
import Page from "../components/ui/Page";
import { IStatus } from "../utils/status";
import { selectStatusApp } from "../store/reducers/appReducer";
import Routes from "../routes";
import useAppDispatch from "./useAppDispatch";

/**
 * Hook that render app
 * @returns
 */
const useRenderAppStatus = () => {
    const { useSelector } = useAppDispatch();
    const statusApp = useSelector(selectStatusApp);

    const renderOnStatus = (): React.ReactNode => {
        let content = null;
        switch (statusApp) {
            case IStatus.CLOSED:
                content = <Page>closed</Page>;
                break;
            case IStatus.PRE:
                content = (
                    <Page>
                        <Countdown
                            value={
                                Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30
                            }
                        />
                    </Page>
                );
                break;
            case IStatus.OPEN:
                content = (
                    <>
                        <AppHeader />
                        <Routes />
                        <NavigationBar />
                    </>
                );

                break;
            case IStatus.POST:
                content = <Page>post</Page>;
                break;
            default:
                content = <></>;
                break;
        }

        return content;
    };

    return { renderOnStatus };
};

export default useRenderAppStatus;
