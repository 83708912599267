import React from "react";
import Modal from "../Modal";
import classNames from "classnames";
import QAFormWrapper from "../../ui/qaWrapper/QAWrapper";

interface FaqModalProps {}

const QAModal: React.FC<FaqModalProps> = () => {
    const IsLoading = false;
    const body = <QAFormWrapper />;

    return (
        <Modal
            data-testid="qa-modal"
            open
            centered
            className={classNames("qa-modal")}
            body={body}
            isLoading={IsLoading}
        />
    );
};

export default QAModal;
